import "./index.css";
import { removeSidebar} from "../../utils";
import columns from "../../components/columns/Transaction.column";
import { DataTable } from "../../components/UiElement/index";
import Pagination from "../../components/UiElement/Pagination";
import { useEffect, useState } from "react";
import { getTransactionListService } from "../../services/transaction";
import { Select } from "antd";

function Transaction(){
    const [purchasePlatform, setpurchasePlatform] = useState([{name:'IOS', value:'IOS'},{name:'ANDROID', value:'ANDROID'}]);
    const [state, setState] = useState({
         loader: true,
      });
    const [transaction, setTransactionList] = useState({
        code: 0,
        message: "Transaction list",
        transactionList: [],
        limit: 3,
        page: 1,
        totalResult: 0,
        list: [],
      });
      const [paginationQuery, setPageQuery] = useState({ page: 1, limit: 20 });

      useEffect(()=>{
        setState({...state, loader:true});
        try {
            (async ()=>{
                const data = await getTransactionListService(paginationQuery)
                if(data?.code == 200){
                    setTransactionList(data);
                    setState({...state, loader:false});
                }else{
                    setTransactionList(data);
                    setState({...state, loader:false});
                }
            })();
        } catch (error) {
            setState({...state, loader:false})
        }
      },[paginationQuery])
    return(
        <>
        <div className="page-content" onClick={() => removeSidebar()}>
          <div className="container-fluid">
            <div className="page-filters">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="pageTitle">
                  <h1>Transactions</h1>
                </div>
                <div className="page-right-filter d-flex align-items-center">
                  <div className="statusFilter mr-3">
                    <Select
                      placeholder="Please Select Platform"
                      size="large"
                      name="purchasePlatform"
                      value={paginationQuery?.purchasePlatform}
                      // defaultValue={['a10', 'c12']}
                      onChange={(value) =>
                        setPageQuery({ ...paginationQuery,page:1, purchasePlatform: value })
                      }
                      options={purchasePlatform.length ? purchasePlatform : []}
                    />
                  </div>
                  <div className="search_filter  mr-3">
                    <button
                      onClick={() =>
                        setPageQuery({
                          ...paginationQuery,
                          purchasePlatform: null,
                        })
                      }
                      type="button"
                      title="Export"
                      className="btn btn-primary btn-icon"
                    >
                      Reset
                    </button>
                  </div>
                 
                </div>
              </div>
            </div>
            <div className="card tableData">
              <DataTable
                data={transaction?.list?.length ? transaction?.list : []}
                tableLoader={state?.loader}
                columns={columns({state,setState,paginationQuery})}
              />
            </div>
  
            <div className="container-fluid">
              <div className="row mt-30">
                <div className="col-6"></div>
                <div className="col-2"></div>
                <Pagination
                  itemsPerPage={transaction?.totalResult}
                  outOfNumber={transaction?.list?.length}
                  itemLimit={paginationQuery?.limit}
                  paginationQuery={paginationQuery}
                  setPageQuery={setPageQuery}
                />
              </div>
            </div>
  
           
          </div>
        </div>
      </>
    )
}

export default Transaction;