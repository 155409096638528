import { SubCategoryDetails } from "../../../pages";
import routesMap from "../../../routeControl/User";

export default function route() {
  return [
    {
      path: routesMap.SubCategoryDetails.path,
      name: "SubCategoryDetails",
      key: routesMap.SubCategoryDetails.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      element: <SubCategoryDetails />,
    },
  ];
}
