
const Master = {
    getUserList: {
      url: `/master/users`,
      method: "GET",
    },
    getAppVersion: {
        url: `/master/app-versions`,
        method: "GET",
      },
      getvalidityPlans: {
        url: `/master/validity-plans`,
        method: "GET",
      },
      getAppVersion: {
        url: `/master/app-versions`,
        method: "GET",
      },
      getCategoriesListing: {
        url: `/master/category`,
        method: "GET",
      },
      getSubCategoriesListing:(id)=>( {
        url: `/master/sub-category/${id}`,
        method: "GET",
      }),
  };
  export default Master;
  