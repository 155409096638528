import config from "../../config";


const PlaylistIcons = {
  get: {
    url: `/admin/folder`,
    method: "GET",
  },
  add: {
    url: `/admin/folder`,
    method: "POST",
  },
  status :(id)=>({
    url: `/admin/folder/${id}`,
    method: "PATCH",
  }),
  delete :(id)=>({
    url: `/admin/folder/${id}`,
    method: "DELETE",
  }),
};
export default PlaylistIcons;
