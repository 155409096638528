import config from "../../config";


const Subscription = {
  getList: {
    url: `/admin/subscription`,
    method: "GET",
  },
  addsubscription:{
    url: `/admin/subscription`,
    method: "POST",
  },
  editsubscription: (id)=>({
    url: `/admin/subscription/${id}`,
    method: "PUT",
  }),
  subscriptionStatus :(id)=>({
    url: `/admin/subscription/${id}`,
    method: "PATCH",
  }),
  deleteEndPoint :(id)=>({
    url: `/admin/subscription/${id}`,
    method: "DELETE",
  }),
};
export default Subscription;
