import { PlayListIcons } from "../../../pages";
import routesMap from "../../../routeControl/User";

export default function route() {
  return [
    {
      path: routesMap.playlistIcons.path,
      name: "playlistIcons",
      key: routesMap.playlistIcons.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      element: <PlayListIcons />,
    },
  ];
}
