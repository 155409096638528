
const termAndController = {
    getTermAndCondition: {
      url: `/admin/termAndController`,
      method: "GET",
    },
    AddUpdateTermAndCondition: {
      url: `/admin/termAndController`,
      method: "POST",
    },
  };
  export default termAndController;
  