import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "category",
  initialState: {
    categoryLoader: false,
    categoryList: [],
    subCategoryList: [],
    subCategoryFileDetail: [],
    subCategoryFileList: [],
  },
  reducers: {
    setCategoryLoader: (state, action) => {
      return {
        ...state,
        categoryLoader: action.payload,
      };
    },
    setCategoryList: (state, action) => {
      return {
        ...state,
        categoryList: action.payload,
      };
    },
    setSubCategoryList: (state, action) => {
      return {
        ...state,
        subCategoryList: action.payload,
      };
    },
    setSubCategoryFileDetail: (state, action) => {
      return {
        ...state,
        subCategoryFileDetail: action.payload,
      };
    },
    setSubCategoryFileList: (state, action) => {
      return {
        ...state,
        subCategoryFileList: action.payload,
      };
    },
  },
});

export const { setCategoryLoader,setCategoryList,setSubCategoryList,setSubCategoryFileDetail } = categorySlice.actions;

export default categorySlice.reducer;
