import { Link } from "react-router-dom";
import routesMap from "../../../routeControl/userRouteMap";
import { useLocation } from "react-router-dom";
import ClockCircleOutlined from "@ant-design/icons/ClockCircleOutlined";
import "./sideBar.css";
import {
  AlertOutlined,
  DashboardOutlined,
  DollarOutlined,
  FileAddOutlined,
  FileProtectOutlined,
  PlayCircleOutlined,
  ProfileOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
function Sidebar() {
  const { pathname } = useLocation();
  return (
    <div
      className="vertical-menu text-center position-fixed top-0 bottom-0"
      data-bs-scroll="true"
      data-bs-backdrop="false"
      tabIndex="-1"
      id="sideMenu"
    >
      <div className="navbar-brand-box">
        <Link to="/" className="logo logo-dark">
          <span className="logosm">
            <label>Sowilo</label>
          </span>
        </Link>
        <button
          hidden
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          data-bs-target="#sideMenu"
          aria-label="Close"
        ></button>
      </div>
      {/* <!--- Sidemenu --> */}
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className={pathname === "/dashboard" ? `active` : ""}>
            <Link to="/dashboard" className="waves-effect">
              <span className="svg-icon mx-auto d-flex align-items-center justify-content-center">
                <DashboardOutlined />
              </span>
              <label>Dashboard</label>
            </Link>
          </li>
          <li className={pathname === "/home" ? `active` : ""}>
            <Link to="/home" className="waves-effect">
              <span className="svg-icon mx-auto d-flex align-items-center justify-content-center">
                {/* <i className="ri-user-line"></i> */}
                <UserOutlined />
              </span>
              <label>Users</label>
            </Link>
          </li>
          {/* <li  className={pathname=== "/content-management" ?`active` :""}>
                     <Link to="/content-management" className="waves-effect">
                        <span className="svg-icon mx-auto d-flex align-items-center justify-content-center">
                        </span>
                        <label>CMS</label>
                     </Link>
                  </li> */}
          <li
            className={
              pathname.includes("/subCategory/") ||
              pathname.includes("/category") || pathname.includes("/sub-category-details/")
                ? `active`
                : ""
            }
          >
            <Link to="/category" className="waves-effect">
              <span className="svg-icon stroke-icon mx-auto d-flex align-items-center justify-content-center">
                {/* <i className="ri-file-list-line"></i> */}
                <ProfileOutlined />
              </span>
              <label>Category & Sub Cat...</label>
            </Link>
          </li>
          <li className={pathname === "/all-files" ? `active` : ""}>
            <Link to="/all-files" className="waves-effect">
              <span className="svg-icon stroke-icon mx-auto d-flex align-items-center justify-content-center">
                {/* <i className="ri-file-line"></i> */}
                <FileAddOutlined />
              </span>
              <label>All Files</label>
            </Link>
          </li>
          {/* <li
            className={pathname === routesMap.Subscription.path ? `active` : ""}
          >
            <Link to={routesMap.Subscription.path} className="waves-effect">
              <span className="svg-icon stroke-icon mx-auto d-flex align-items-center justify-content-center">
                <DollarOutlined />
              </span>
              <label>Subscription</label>
            </Link>
          </li> */}
          <li className={pathname.includes("/tracking") ? `active` : ""}>
            <Link to="/tracking" className="waves-effect">
              <span className="svg-icon stroke-icon mx-auto d-flex align-items-center justify-content-center">
                <ClockCircleOutlined />
              </span>
              <label>Tracking</label>
            </Link>
          </li>
          <li className={pathname === "/notifications" ? `active` : ""}>
            <Link to="/notifications" className="waves-effect">
              <span className="svg-icon stroke-icon mx-auto d-flex align-items-center justify-content-center">
                {/* <i className="ri-notification-3-line"></i> */}
                <AlertOutlined />
              </span>
              <label>Alert</label>
            </Link>
          </li>
          <li className={pathname === "/playlist/icons" ? `active` : ""}>
            <Link to="/playlist/icons" className="waves-effect">
              <span className="svg-icon stroke-icon mx-auto d-flex align-items-center justify-content-center">
                {/* <i className="ri-play-line"></i> */}
                <PlayCircleOutlined />
              </span>
              <label>Playlist Icons</label>
            </Link>
          </li>
          <li className={pathname === "/transaction" ? `active` : ""}>
            <Link to="/transaction" className="waves-effect">
              <span className="svg-icon stroke-icon mx-auto d-flex align-items-center justify-content-center">
                {/* <i className="ri-settings-2-line"></i> */}
                <FileProtectOutlined />
              </span>
              <label>Transactions</label>
            </Link>
          </li>
          <li className={pathname === "/setting" ? `active` : ""}>
            <Link to="/setting" className="waves-effect">
              <span className="svg-icon stroke-icon mx-auto d-flex align-items-center justify-content-center">
                {/* <i className="ri-settings-2-line"></i> */}
                <SettingOutlined />
              </span>
              <label>Settings</label>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
