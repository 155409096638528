import loadable from "@loadable/component";

export const Home = loadable(() => import("./Home/home"));
export const Login = loadable(() => import("./Auth/login"));
export const ContentManagement = loadable(() => import("./ContentManagement/contentManagement"));
export const Notification = loadable(() => import("./Notifications/index"));
export const Category = loadable(() => import("./Category/index"));
export const Upload = loadable(() => import("./AllFiles/index"));
export const Subscription = loadable(() => import("./Subscription/index"));
export const Dashboard = loadable(() => import("./Dashboard/index"));
export const SubCategory = loadable(() => import("./SubCategory/index"));
export const Setting = loadable(() => import("./Setting/index"));
export const PlayListIcons = loadable(() => import("./PlayListIcons/index"));
export const Tracking = loadable(() => import("./Tracking/index"));
export const TrackingIcons = loadable(() => import("./TrackingIcons/index"));
export const Transaction = loadable(() => import("./Transaction/index")); 
export const SubCategoryDetails = loadable(() => import("./Category/subCategoryDetails")); 

