
const HomeSettings = {
  getHomeHeading: {
    url: `/admin/home-heading`,
    method: "GET",
  },
  AddHome: {
    url: `/admin/home-heading`,
    method: "POST",
  },
  UpadateHome: ()=>({
    url: `/admin/home-heading`,
    method: "POST",
  }),
  StatusHome:(id)=>({
    url: `/admin/home-heading/${id}`,
    method: "PATCH",
  }),
  deleteHome :(id)=>({
    url: `/admin/home-heading/${id}`,
    method: "DELETE",
  }),
  positionChange :{
    url: `/admin/home-heading/change-order`,
    method: "POST",
  }
};
export default HomeSettings;
