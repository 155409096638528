import { createSlice } from "@reduxjs/toolkit";
import {
  getLocalStorageToken,
  logger,
  modalNotification,
  removeLocalStorageToken,
} from "../../utils";
import axios from "axios";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: false,
    userData: {},
    isUploading: true,
  },
  reducers: {
    loginAction: (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        userData: action.payload,
        isUploading: true,
      };
    },

    logoutAction: (state) => {
      return {
        ...state,
        isLoggedIn: false,
        userData: {},
      };
    },
    uploadAction: (state, action) => {
      return {
        ...state,
        isUploading: action.payload,
      };
    },
    updateUserDataAction: (state, action) => {
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload,
        },
      };
    },
  },
});

export const { loginAction, updateUserDataAction, logoutAction, uploadAction } =
  authSlice.actions;

export const login = (data) => async (dispatch) => {
  try {
    dispatch(loginAction(data));
  } catch (error) {
    logger(error);
  }
};

export const logout = (history,showMessage) => async (dispatch) => {
  try {
    axios.get('https://api.test.sowilo.app/admin/logout',{
      headers:  {
        Authorization: getLocalStorageToken()
      }
    })
   if (!showMessage){
    modalNotification({
      type: "success",
      message: "Logout",
      description: "Logout Successful",
    });
  }
    dispatch(logoutAction());
    removeLocalStorageToken();
    history("/");
  } catch (error) {
    logger(error);
  }
};

export const checkUpload = (data) => async (dispatch) => {
  dispatch(uploadAction(data));
};

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;

export const selectIsUploading = (state) => state.auth.isUploading;

export const selectUserData = (state) => {
  if (
    selectIsLoggedIn(state) &&
    Object.keys(state.auth.userData).length &&
    Object.keys(state.auth.userData).length
  ) {
    return state.auth.userData;
  }
  return {};
};

export const selectUserToken = (state) => {
  if (selectIsLoggedIn(state) && Object.keys(state.auth.userData).length) {
    return state.auth.userData.token;
  }
  return false;
};

export default authSlice.reducer;
