import config from "../../config";

const SubCategory = {
  addEditSubCategory: {
    url: `/admin/sub-category`,
    method: "POST",
  },
  addSubCategory: {
    url: `/admin/sub-category`,
    method: "POST",
  },
  getSubCategoryWise: (id)=>({
    url: `/admin/sub-category/${id}`,
    method: "GET",
  }),
  editSubCategory: (id)=>({
    url: `/admin/sub-category/${id}`,
    method: "PUT",
  }),
  subCategoryStatus :(id)=>({
    url: `/admin/sub-category/${id}`,
    method: "PATCH",
  }),
  deleteSubCategory :()=>({
    url: `/admin/sub-category`,
    method: "DELETE",
  }),
  deleteSubCat :(id)=>({
    url: `/admin/sub-category/${id}`,
    method: "DELETE",
  }),
};
export default SubCategory;
