import config from "../../config";


const User = {
  getUserList: {
    url: `/admin/user`,
    method: "GET",
  },
  addUser:{
    url: `/admin/user`,
    method: "POST",
  },
  editUser: (id)=>({
    url: `/admin/user/${id}`,
    method: "PUT",
  }),
  userStatus :(id)=>({
    url: `/admin/user/${id}`,
    method: "PATCH",
  }),
  deleteUser :(id)=>({
    url: `/admin/user/${id}`,
    method: "DELETE",
  }),
};
export default User;
