import loadable from "@loadable/component";

export const ModalComponent = loadable(() => import("./Modal/Index"));
export const DataTable = loadable(() => import("./DataTable/index"));
export const Pagination = loadable(() => import("./Pagination/index"));
export const DeleteBox = loadable(() => import("./DeleteBox/index"));
export const GlobalLoader = loadable(() => import("./GlobalLoader/index"));
export const DraggableList = loadable(() => import("./ReactPosition/index"));
export const ShowImageScreen = loadable(() => import("./ShowScreen/index"));
export const AddEditTrackingIcons = loadable(() => import("./IconCards/index"));
