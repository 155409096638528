import { denodeify } from "q";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
// import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

export default function Pagination({
  hide,
  itemsPerPage,
  outOfNumber,
  itemLimit,
  paginationQuery,
  setPageQuery,
}) {
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const noOfPages =
      itemsPerPage > 0 &&
      parseInt(Math.ceil(parseFloat(itemsPerPage / itemLimit)));
    setPageCount(noOfPages);
  }, [itemsPerPage]);

  const handlePageClick = ({ selected }) => {
    setPageQuery({ ...paginationQuery, page: selected + 1 });
  };
  const num = paginationQuery?.page * itemLimit + 1 - itemLimit;
  const OutOfnum =
    paginationQuery?.page * itemLimit - (itemLimit - outOfNumber);
  return (
    <>
      <div className="col-6 ">
        {itemsPerPage > 0 ? (
          <div className="dataTables_info">
            Showing {num ? num : ""} to {OutOfnum ? OutOfnum : ""} of{" "}
            {itemsPerPage} entries
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="col-6 d-flex justify-content-end">
        {!hide && (
          <ReactPaginate
            breakLabel="..."
            shape="rounded"
            variant="outlined"
            nextLabel="Next >>"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="<< Prev"
            renderOnZeroPageCount={null}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
            forcePage={paginationQuery?.page - 1}
          />
        )}
      </div>
    </>
  );
}
