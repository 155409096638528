import config from "../../config";

const Settings = {
  fileTypes: {
    url: `/admin/file-types`,
    method: "GET",
  },
  checkJourneyTeamcheck: {
    url: `/admin/check-journey-team`,
    method: "GET",
  },
  AddfileTypes: {
    url: `/admin/file-type`,
    method: "POST",
  },
  EditTypes: (id) => ({
    url: `/admin/file-type/${id}`,
    method: "PUT",
  }),
  StatusTypes: (id) => ({
    url: `/admin/file-type/${id}`,
    method: "PATCH",
  }),
  deleteTypes: (id) => ({
    url: `/admin/file-type/${id}`,
    method: "DELETE",
  }),
};
export default Settings;
