import home from "./Home";
import contentManagement from "./contentManagement";
import notification from "./Notification";
import category from "./Category";
import allFiles from "./allFiles";
import subscription from "./subscription";
import dashboard from "./Dashboard";
import SubCategory from "./subCategory";
import Setting from "./systemSetting";
import playlistIcons from "./playlistIcons";
import Tracking from "./Tracking";
import Transaction from "./Transaction";
import SubCategoryDetails from "./subCategoryDetails";

const AccessControl = {
  ...home,
  ...contentManagement,
  ...notification,
  ...category,
  ...allFiles,
  ...subscription,
  ...dashboard,
  ...SubCategory,
  ...Setting,
  ...playlistIcons,
  ...Tracking,
  ...Transaction,
  ...SubCategoryDetails
};

export default AccessControl;
