import moment from "moment";


import  "../../pages/Transaction/index.css";

const getClass = (row)=>{
  if(row?.status == 'purchased'){
    return 'badge-success'
  }else if(row?.status == 'canceled'){
    return 'badge-warning'
  }else{
    return 'badge-danger'
  }
}
export const columns = ({
  paginationQuery,
}) => {
  return [
    {
      title: "S.no.",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        const num =
          paginationQuery?.page * paginationQuery?.limit +
          1 -
          paginationQuery?.limit;
        return num + index;
      },
    },
    {
      dataIndex: "name",
      title: "Name",
      render: (cell, row) =>
      <>
        
        <span>{row?.userId ? row.userId.name : '-' }</span><br/>
        <span>{row?.userId ? row.userId.email : '-' }</span>
        </>
      
    },

    {
      dataIndex: "purchasePlatform",
      title: "	Purchase Platform",
    },
    {
      dataIndex: "transaction_id",
      title: "Transaction ID",
      render: (cell, row) =>
      row?.transaction_id ? row.transaction_id : '-'
    },
    {
      dataIndex: "startTimeMS",
      title: "Start Date",
      render: (cell, row) =>
      row?.startTimeMS ? moment(parseInt(row?.startTimeMS)).format("YYYY-MM-DD HH:mm") : '-'
    },
    {
      dataIndex: "expiryTimeMS",
      title: "Expiry Date",
      render: (cell, row) =>
      row?.expiryTimeMS ? moment(parseInt(row?.expiryTimeMS)).format("YYYY-MM-DD HH:mm") : '-'
    },
    {
      dataIndex: "product_id",
      title: "Plan Name",
      render: (cell, row) => {
        let planname ;
        if( row?.product_id == 'sub_weekly'){
          planname = 'Weekly';
        }else if(row?.product_id == 'sub_monthly'){
          planname = 'Monthly';
        }else if(row?.product_id == 'sub_annually'){
          planname = 'Annually';
        }else{
          planname = '-';
        }
        return planname;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (cell, row) =>
      <>
      <span className={"status-for-transation" +' ' + getClass(row)}>{row?.status ? row.status.charAt(0).toUpperCase() + row.status.slice(1) : '-'}</span>
      
      </>
    },

      
  ];
};

export default columns;
