import config from "../../config";

const AllFiles = {
  fileUpload: {
    url: `/admin/file-upload`,
    method: "POST",
  },
  addFiles:{
    url: `/admin/add-upload-file-details`,
    method: "POST",
  },
  editFiles:{
    url: `/admin/add-upload-file-details`,
    method: "POST",
  },
  getListing :{
    url: `/admin/files`,
    method: "GET",
  },
  getFileDetails :{
    url: `/admin/files-details`,
    method: "GET",
  },
  fileStatus :(id)=>({
    url: `/admin/file-details/${id}`,
    method: "PUT",
  }),
  singleFileUpload :(id)=>({
    url: `/admin/file-details/${id}`,
    method: "PUT",
  }),
  deleteFile :(categoryId,subCategoryId)=>({
    url: `/admin/file-details/${categoryId}/${subCategoryId}`,
    method: "DELETE",
  }),
};
export default AllFiles;
