import { Upload } from "../../../pages";
import routesMap from "../../../routeControl/User";

export default function route() {
  return [
    {
      path: routesMap.AllFiles.path,
      name: "AllFiles",
      key: routesMap.AllFiles.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      element: <Upload />,
    },
  ];
}
