import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ModalComponent from "../Modal/Index";
import { logout } from "../../../redux/auth/index.slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangePassword from "../../../pages/ChangePassword";
import Dropdown from "react-bootstrap/Dropdown";
import toggleIcon from "./../../../assets/icons/toggle-icon.svg";
import { Button } from "antd";
import { UserOutlined } from "@ant-design/icons";

export default function AdminHeader() {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [passwordModal, setPassModal] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const dispatch = useDispatch();
  const history = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/home") {
      setShowSetting(true);
    } else setShowSetting(false);
  }, [pathname]);
  return (
    <>
      <header id="page-topbar" className="position-fixed top-0 start-0 end-0">
        <div className="navbar navbar-header">
          <div className="d-flex align-content-center">
            <div className="navbar-brand-box me-3" hidden>
              <a href="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    loading="lazy"
                    src="images/logo.svg"
                    alt="logo-sm-dark"
                  />
                </span>
              </a>
            </div>
            <button
              hidden
              className="btn btn-link toggle_btn"
              type="button"
              data-toggle="offcanvas"
              data-target="#sideMenu"
            >
              <img src={toggleIcon} alt="Toggle Icon" />
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block"></div>
            <div className="dropdown d-inline-block"></div>
            <div className="dropdown d-inline-block user-dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  id="page-header-user-dropdown"
                  className="header-item waves-effect"
                >
                  {/* <i className="ri-account-circle-line user_icon"></i> */}
                  <UserOutlined className="user_icon" />
                  <i className="knam-icon down-arrow position-absolute top-50 end-0 translate-middle-y"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/home">Users</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPassModal(true)} href="#">
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setShowModal(!showModal)}
                    href="#"
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>

      <ModalComponent
        handleClose={() => setPassModal(false)}
        show={passwordModal}
      >
        <ChangePassword setPassModal={setPassModal} />
      </ModalComponent>

      <ModalComponent show={showModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0 text-center w-100 pl-4r">
              Confirm Logout
            </h5>
            <button
              onClick={() => setShowModal(false)}
              type="button"
              className="close"
              data-dismiss="modal"
            >
              {" "}
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12 text-center">
                  <p>Are you sure you want to logout?</p>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="form-group d-flex justify-content-center">
                    <Button
                      onClick={() => dispatch(logout(history))}
                      className="btn border-0 btn-lg btn-dark"
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ModalComponent>
    </>
  );
}
