import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    globalLoader: false,
    subCategoryDetailById: ''
  },
  reducers: {
    setGlobalLoader: (state, action) => {
      return {
        ...state,
        globalLoader: action.payload,
      };
    },
    setSubCategoryDetail: (state, action) => {
      return {
        ...state,
        subCategoryDetailById: action.payload,
      };
    },
  },
});

export const { setGlobalLoader,setSubCategoryDetail } = commonSlice.actions;

export default commonSlice.reducer;
