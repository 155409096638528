const TrackingIcons = {
  getTrackingList: () => ({
    url: `/admin/tracking-category-details`,
    method: "GET",
  }),
  addEditTrackingIcons: () => ({
    url: `/admin/tracking-category-details`,
    method: "POST",
  }),
  deleteTrackingIcons: {
    url: `/admin/tracking-category-details`,
    method: "DELETE",
  },
};
export default TrackingIcons;
