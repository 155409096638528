const Tracking = {
  getTrackingList: {
    url: `/admin/tracking-category`,
    method: "GET",
  },
  addTracking: {
    url: `/admin/tracking-category`,
    method: "POST",
  },
  editTracking: (id) => ({
    url: `/admin/tracking-category/${id}`,
    method: "PUT",
  }),
  deleteTracking: (id) => ({
    url: `/admin/tracking-category/${id}`,
    method: "DELETE",
  }),
};
export default Tracking;
