import { Tracking ,TrackingIcons } from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.Tracking.path,
      name: "Tracking",
      key: routesMap.Tracking.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      element: <Tracking />,
    },
    {
      path: routesMap.Tracking.pathId,
      name: "Tracking:pathId",
      key: routesMap.Tracking.pathId,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      element: <TrackingIcons  />,
    },
  ];
}
