import { ContentManagement } from "../../../pages";
import routesMap from "../../../routeControl/User";

export default function route() {
  return [
    {
      path: routesMap.ContentManagement.path,
      name: "ContentManagement",
      key: routesMap.ContentManagement.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      element: <ContentManagement />,
    },
  ];
}
