import { Transaction } from "../../apiEndPoints";
import { logger } from "../../utils";
import APIrequest from "../../utils/axios";

export const getTransactionListService = async (queryParams) => {
    try {
      const payload = {
        ...Transaction.getTransactionList,
        queryParams,
      };
      const res = await APIrequest(payload);
      return res;
    } catch (error) {
      logger(error);
      throw error;
    }
  };