const teams = {
  getTeams: {
    url: `/admin/meetTheTeam`,
    method: "GET",
  },
  AddTeams: {
    url: `/admin/meetTheTeam`,
    method: "POST",
  },
};
export default teams;
