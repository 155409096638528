import config from "../../config";

const Category = {
  getCategoryList: {
    url: `/admin/category`,
    method: "GET",
  },
  addCategory:{
    url: `/admin/category`,
    method: "POST",
  },
  editCategory: (id)=>({
    url: `/admin/category/${id}`,
    method: "PUT",
  }),
  CategoryStatus :(id)=>({
    url: `/admin/category/${id}`,
    method: "PATCH",
  }),
  deleteCategory :(id)=>({
    url: `/admin/category/${id}`,
    method: "DELETE",
  }),
  reorderCategory:{
    url: `/admin/category/change-order`,
    method: "POST",
  },
  reorderSubCategory:{
    url: `/admin/sub-category/change-order`,
    method: "POST",
  },
};
export default Category;
